import React from 'react'
import './about.css'
import ME from '../../assets/me-avatar.png'
import {GiGraduateCap} from 'react-icons/gi'
import {MdWorkHistory} from 'react-icons/md'
import {MdOutlineInterests} from 'react-icons/md'

const About = () => {
  return (
    <section id='about'>
      <h2>Something About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <GiGraduateCap className='about__card-icon'/>
              <h5>Education</h5>
              <small>Master in Data Science and Business Analytics</small>
            </article>

            <article className='about__card'>
              <MdWorkHistory className='about__card-icon'/>
              <h5>Current Job</h5>
              <small>IoT Data Analyst in Ingersoll Rand</small>
            </article>

            <article className='about__card'>
              <MdOutlineInterests className='about__card-icon'/>
              <h5>Interests</h5>
              <small>Analytics, Modelling, Visualization & Integration with Business</small>
            </article>

          </div>

          <p>I graduated from Bocconi University with a Master degree in DSBA and currently employeed as Data Analyst focusing on
            Internet of Things in Ingersoll Rand. With great passion on Data, especially how insights generated from analytics and models ,how they empower the business. I'm exploring the immensive
            possibility of data. 
          </p>

          <a href="#contact" className='btn btn-primary'>Let's Talk</a>

        </div>
      </div>
    </section>
  )
}

export default About