import React, { useState } from 'react'
import './header.css'
import CTA from './CTA'
import HeaderSocials from './HeaderSocials'
import VerticalCarousel from './VerticalCarousel'
import AVAR1 from '../../assets/photo1.png'
import AVAR2 from '../../assets/photo2.png'
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const data = [
  {
    avatar: AVAR1,
    note: "avar1"
  },
  {
    avatar: AVAR2,
    note: "avar2"
  }
]

const Header = () => {
  return (
    <header>
      <div className="container header__container">
      <h5 className="text-light">Welcome to My Portfolio</h5>
        <h1>Hello, I'm Charlie Jin</h1>
        <CTA />
        <HeaderSocials />
        <VerticalCarousel />

        <a href="#contact" className='scroll__down'>Scroll Down</a>

        <Swiper className="header__avatarcarousel"
              modules={[Pagination, Navigation]}
              spaceBetween={40}
              slidesPerView={1}
              pagination={{ clickable: true }}
        >
          {
            data.map(({avatar, note}, index) => {
              return (
                <SwiperSlide key={index} className='avatar'>
                  <img src={avatar} alt={note} />
                </SwiperSlide>
              )
            })
          }
        </Swiper>

      </div>
    </header>
  )
}

export default Header