import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub, FaFacebook} from 'react-icons/fa'

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href="https://linkedin.com/in/charlie-zining-jin-962555154" target="_blank"><BsLinkedin /></a>
        <a href="https://github.com/ZiningJin" target="_blank"><FaGithub /></a>
        <a href="https://www.facebook.com/profile.php?id=100018597332444" target="_blank"><FaFacebook /></a>
    </div>
  )
}

export default HeaderSocials