import React, { useState, useEffect } from "react";
import "animate.css/animate.min.css";

const VerticalCarousel = () =>  {
  const [currentIndex, setCurrentIndex] = useState(0);
  const items = ["Analytics and Insights", "Industrial IoT", "Business Solution"];

  const nextItem = () => {
    setCurrentIndex((currentIndex + 1) % items.length);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      nextItem();
    }, 3000);
    return () => clearInterval(intervalId);
  }, [currentIndex]);

  return (
    <div className="VerticalCarousel">
      <div className="animate__animated animate__bounce">
          {items[currentIndex]}
      </div>
    </div>
  );
}

export default VerticalCarousel;
