import React from 'react'
import './footer.css'
/*
import {BsFacebook} from 'react-icons/bs'
import {BsInstagram} from 'react-icons/bs'
import {BsTwitter} from 'react-icons/bs'
*/

const Footer = () => {
  return (
    <footer>
      <a href="#" className='footer__logo'>Charlie Jin</a>
      <ul className='permalinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#skill">Skill</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>


      <div className="footer__copyright">
        <small>&copy; charlie-jin.com. All rights reserved</small>
      </div>
    </footer>
  )
}

/*
      <div className='footer__socials'>
        <a href="https://facebook.com"><BsFacebook /></a>
        <a href="https://twitter.com"><BsTwitter /></a>
        <a href="https://instagram.com"><BsInstagram /></a>
      </div>
  */

export default Footer