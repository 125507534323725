import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/port_img1.png'
import IMG2 from '../../assets/port_img2.png'
import IMG3 from '../../assets/port_img3.png'

const data = [
  {
    id: 1,
    image: IMG1,
    title: 'Ecomm Recommendation Engine on AWS',
    github: 'https://github.com/ZiningJin/ECom_RecomEngine',
    demo: 'https://www.youtube.com/watch?v=0_sCH_NpI8Q'
  },
  {
    id: 2,
    image: IMG2,
    title: 'WebCrawler Toolbox for Project Databases',
    github: 'https://github.com/ZiningJin/WebCrawler',
    demo: 'https://webcrawler-leads.com'
  },
  {
    id: 3,
    image: IMG3,
    title: 'Leads Tracker with Salesforce & MySQL',
    github: 'https://github.com/ZiningJin/LeadsTracker',
    demo: 'https://github.com/ZiningJin/LeadsTracker/blob/main/flowchart.drawio.png'
  },
]

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Projects</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio__container">
        {
          data.map(({id, image, title, github, demo}) => {
            return (
              <article className='portfolio__item'>
              <div className="portfolio__item-image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <div className="portfolio__item-cta">
              <a href={github} className='btn' target='_blank'>Github</a>
              <a href={demo} className='btn btn-primary' target='_blank'>Live Demo</a>
              </div>
            </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default Portfolio