import React from 'react'
import './skill.css'
import { FaPython, FaAws, FaDocker } from 'react-icons/fa';
import { DiJavascript } from "react-icons/di";
import { SiRstudio, SiMysql, SiPostgresql, SiMongodb, SiSnowflake, SiTableau, SiDbt, SiStreamlit, SiPlotly } from 'react-icons/si';


/* import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { buildStyles,CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'; */

/* // Radial separators
import RadialSeparators from "./RadialSeparators";

const Skill = () => {
    const CustomPrevArrow = ({ onClick }) => (
        <button onClick={onClick} className="custom-arrow custom-prev-arrow">
          <FaChevronLeft />
        </button>
      );
    const CustomNextArrow = ({ onClick }) => (
        <button onClick={onClick} className="custom-arrow custom-next-arrow">
          <FaChevronRight />
        </button>
    );    
        
  const data = [
    { percentage: 80, color: '#d34bab', skillname: 'Python',logo_src: 'https://upload.wikimedia.org/wikipedia/commons/c/c3/Python-logo-notext.svg'},
    { percentage: 75, color: '#98337a', skillname: 'R',logo_src:'https://en.wikipedia.org/wiki/R_%28programming_language%29#/media/File:R_logo.svg'},
    { percentage: 85, color: '#98336b', skillname: 'PostgreSQL',logo_src:'https://en.wikipedia.org/wiki/PostgreSQL#/media/File:Postgresql_elephant.svg'},
    { percentage: 80, color: '#69219b', skillname: 'MongoDB', logo_src:'https://upload.wikimedia.org/wikipedia/commons/9/93/MongoDB_Logo.svg'},
    { percentage: 75, color: '#e9a7a7', skillname: 'React', logo_src:'https://en.wikipedia.org/wiki/React_%28software%29#/media/File:React-icon.svg'},
    { percentage: 75, color: '#e9a7b6', skillname: 'NodeJS', logo_src:'https://en.wikipedia.org/wiki/Node.js#/media/File:Node.js_logo.svg'}
  ];

  const options = {
    responsive: {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    },
    infinite: true,
    arrows: true,
    swipeable: true,
    draggable: true,
    centerMode: false,
    itemClass: 'carousel-item-padding-40-px',
  };

  return (
    <section id='skill'>
        <div className="container skill__container">

            <h2>My SkillSet</h2>
            <p>During past study in university and work experience, I'm familar with SQL language PostgreSQL/MySQL/MongoDB.</p>
            <p>Python is my main tool for ETL and modelling during Data Engineering work routine and R is my favorite data visualizing toolbox.</p>
            <p>As a junior full stack developer, I use Node as backend tool while React for frontend.</p>
            <Carousel {...options} CustomPrevArrow={<CustomPrevArrow />} CustomNextArrow={<CustomNextArrow />}>
                {data.map((item, index) => (
                     <CircularProgressbarWithChildren
                     value={item.percentage}
                     text={`${item.skillname}`}
                     strokeWidth={7}
                     styles={buildStyles({
                       strokeLinecap: "butt",
                       textSize: "8px",
                       pathColor: item.color,
                       textColor: item.color,
                     })}
                   >
                     <RadialSeparators
                       count={12}
                       style={{
                         background: "#fff",
                         width: "2px",
                         // This needs to be equal to props.strokeWidth
                         height: `${7}%`
                       }}
                     />
                   </CircularProgressbarWithChildren>
                ))
                }

            </Carousel>

        </div>
    </section>
  )
}

 */

const skills = {
  programming_languages: [
      { name: "Python", icon: <FaPython /> },
      { name: "R", icon: <SiRstudio /> },
      { name: "MySQL", icon: <SiMysql /> },
      {name: "PostgreSQL", icon: <SiPostgresql />},
      { name: "MongoDB", icon: <SiMongodb /> },
      { name: "JavaScript", icon: <DiJavascript /> },

  ],

  cloud_platforms: [
    { name: "AWS", icon: <FaAws /> },
    { name: "Snowflake", icon: <SiSnowflake /> } ,
    { name: "dbt", icon: <SiDbt /> }, 
    { name: "Docker", icon: <FaDocker /> }
  ],
  bi_platforms: [
    { name: "Tableau", icon: <SiTableau /> },
    { name: "Streamlit", icon: <SiStreamlit />},
    { name: "Dash Plotly", icon: <SiPlotly />},
    { name: "Qlik Sense"}
  ]

};



const Skill = () => {
  return (
      <section id='skill'>
        <h5>My Toolbox</h5>
        <h2>Skillset</h2>
        <div className="container skiller__container">

        <div>
          <h3>Programming Languages & Databases</h3>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
              {skills.programming_languages.map((skill, index) => (
                  <div key={index} style={{ margin: '20px', textAlign: 'center' }}>
                      <div style={{ fontSize: '3em' }}>{skill.icon}</div>
                      <p>{skill.name}</p>
                  </div>
              ))}
          </div>
          <h3>Cloud Platforms & Data Engineering Tools</h3>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
              {skills.cloud_platforms.map((skill, index) => (
                  <div key={index} style={{ margin: '20px', textAlign: 'center' }}>
                      <div style={{ fontSize: '3em' }}>{skill.icon}</div>
                      <p>{skill.name}</p>
                  </div>
              ))}
          </div>
          <h3>BI Tools</h3>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
              {skills.bi_platforms.map((skill, index) => (
                  <div key={index} style={{ margin: '20px', textAlign: 'center' }}>
                      <div style={{ fontSize: '3em' }}>{skill.icon}</div>
                      <p>{skill.name}</p>
                  </div>
              ))}
          </div>
      </div>

        </div>
      </section>
  );
};

export default Skill